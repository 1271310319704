import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import lodash from 'lodash';

import { TYPE_PACKAGE } from './../../constants';
import { isShipment, isUnlinkOrder } from './../../helper';
import Package from './../../models/Package';
import { STAGES } from './../../../Notes/constants';
import { url } from './../../../../system/routing';

import PackageCustomer from './PackageCustomer';
import PackageServices from './PackageServices';
import DeletePackageComponent from './../../DeletePackage/DeletePackageContainer';
import CustomerInformation from './../../FormCommon/CustomerInformation/CustomerInformationContainer';
import PrintOfficialStamp from './../../PrintOfficialStamp';
import PrintPackageStampContainer from './../../PrintPackageStamp/PrintPackageStampContainer';
import UnLinkComponent from './../../UnlinkOrder/UnlinkOrderContainer';
import UpdatePackageParams from './../../UpdatePackageParams/UpdatePackageParamContainer';
import CreateShipmentOrderButton from './../../components/CreateShipmentOrderButton';
import Attentions from './../../../Common/components/Attentions';
import FormServices from './../../../Service/FormServices/FormServicesContainer';
import PrintBatchStampButton from '../../components/PrintBatchStampButton';
import CreateMultiSamePackage from '../../components/CreateMultiSamePackage';
import { dispatch } from '../../../../system/store';
import { fetchConsignServiceGroups } from '../../../Service/actions';

class PackageDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.refPrintPackageStamp = null;

        this.state = {
            services: lodash.map(lodash.get(props.package, 'services'), 'id'),
        };
    }

    onEditPackage() {
        let packageData = this.props.package;
        if (packageData.order) {
            this.props.setPackagePage('UpdateRelatedPackage');
        } else {
            this.props.setPackagePage('UpdateAlonePackage');
        }
    }

    createSamePackage() {
        this.props.createPackageCode({
            ...this.props.package,
            id_warehouse: lodash.get(this.props.package, 'id_warehouse_current', ''),
        });
    }

    handleUpdatePackageSuccess = action => {
        const weightNet = lodash.get(action, 'payload.package.weight_net');
        if (weightNet > 0 && this.refPrintPackageStamp && this.refPrintPackageStamp.buttonNode) {
            this.refPrintPackageStamp.buttonNode.focus();
        }
    };

    handleSubmit = data => {
        const { updatePackage } = this.props;
        const pkg = new Package(this.props.package);
        const formattedData = {
            weight_net: pkg.realWeightNetWhenUpdate,
            type: isUnlinkOrder(this.props.package) ? TYPE_PACKAGE.ALONE : TYPE_PACKAGE.RELATED,
            ...data,
        };

        updatePackage(pkg.id, formattedData, this.handleUpdatePackageSuccess);
    };

    doSubmit = lodash.debounce(this.handleSubmit, 500);

    handleRefPrintPackageStamp = ref => {
        this.refPrintPackageStamp = ref;
    };

    handleChangeServices = services => {
        const pkg = new Package(this.props.package);

        this.setState({
            services,
        });

        this.doSubmit({
            identity: pkg.identity,
            services,
            weight_net: pkg.realWeightNetWhenUpdate,
        });
    };

    componentDidMount() {
        const { form, validateWhenMounted } = this.props;

        if (validateWhenMounted) {
            form.validateFields();
        }
    }

    componentDidUpdate(prevProps) {
        const pkg = this.props.package;

        if (
            lodash.get(pkg, 'id') !== lodash.get(prevProps.package, 'id') ||
            lodash.get(pkg, 'identity') !== lodash.get(prevProps.package, 'identity')
        ) {
            this.setState({
                services: lodash.map(lodash.get(pkg, 'services'), 'id'),
            });
        }

        if (lodash.get(pkg, "customer.id_agency") !== lodash.get(prevProps, "package.customer.id_agency")) {
            dispatch(fetchConsignServiceGroups({id_agency: lodash.get(pkg, "customer.id_agency")}))
        }
    }
    render() {
        const { services } = this.state;
        const { form, errors, t } = this.props;
        const packageData = this.props.package;
        const classButtonDisabled = 'a-btn--button-link a-btn--button-link--disabled';
        const classButton = 'a-btn--button-link a-text--blue';
        let disabledPrintStamp = !lodash.get(packageData, 'weight_net');
        let canPrintOfficialStamp = lodash.get(packageData, 'permissions.can_print_official_stamp'); // us 6256 requirement
        const required_volume = lodash.get(packageData, 'required_contents', []).includes('volume');
        const canUpdate = lodash.get(packageData, 'permissions.can_update');
        if (required_volume && lodash.get(packageData, 'volume') <= 0) {
            disabledPrintStamp = true;
            canPrintOfficialStamp = false;
        }
        const canPrintUnofficialStamp = lodash.get(packageData, 'permissions.can_print_unofficial_stamp', false);
        const idPartner = lodash.get(packageData, 'id_partner', undefined);
        const id_operate_package = lodash.get(packageData, 'package_detail.id_operate_package', undefined);
        const canPrintBatchStamp = lodash.get(packageData, 'permissions.can_print_batch_stamp', false);
        const placeOfficalStamp = lodash.get(packageData,"permissions.place_display_official_stamp", []);
        const allowPrintOfficalStamp = placeOfficalStamp.includes("package_created")
        return (
            <Form>
                <Attentions
                    className="amb-16"
                    notes={lodash.get(packageData, 'notes', [])}
                    notices={lodash.get(packageData, 'notices.text', [])}
                    stage={STAGES.CREATE_PACKAGE_BY_BARCODE}
                />
                <Row gutter={{ lg: 24, xl: 48 }}>
                    <Col lg={{ span: 18 }} xl={{ span: 19 }} xxl={{ span: 18 }}>
                        <UpdatePackageParams
                            form={form}
                            services={lodash.map(packageData.services, 'id')}
                            validate={true}
                            onSubmit={this.handleSubmit}
                            package={packageData}
                        />
                    </Col>
                    {canUpdate && isUnlinkOrder(packageData) ? (
                        <Col className="pl-0 _services" lg={{ span: 8 }} xl={{ span: 5 }} xxl={{ span: 6 }}>
                            <div className="a-inline-edit service-edit">
                                <div className="a-inline-edit--input">
                                    <FormServices
                                        singleType
                                        types={isShipment(packageData) ? ['consign'] : undefined}
                                        values={services}
                                        onChange={this.handleChangeServices}
                                    />
                                </div>
                            </div>
                        </Col>
                    ) : (
                        <Col
                            className="a-package--service only-view _services"
                            lg={{ span: 6 }}
                            xl={{ span: 5 }}
                            xxl={{ span: 6 }}
                        >
                            <PackageServices services={packageData.services} />
                        </Col>
                    )}
                </Row>
                {canUpdate && isUnlinkOrder(packageData) ? (
                    <CustomerInformation
                        errors={errors}
                        packageData={packageData}
                        required={false}
                        submitWhenDidMount={false}
                        submitWhenChangeTextValue={false}
                        submitWhenPressEnter
                        updateInfoWhenCustomerChange
                        onSubmit={this.handleSubmit}
                    />
                ) : (
                    <PackageCustomer defaultExpand package={packageData} />
                )}
                <Row gutter={48}>
                    <Col lg={{ span: 24 }} xl={{ span: 24 }} className="a-package--action full-action">
                        <ul className="list-action list-action--left">
                            {<CreateShipmentOrderButton pkg={packageData} wrapper="li" />}

                            {lodash.get(packageData, 'permissions.can_update') ? (
                                <li>
                                    <Button
                                        onClick={this.onEditPackage.bind(this)}
                                        className="a-btn--button-link a-text--blue _btn-edit-package"
                                    >
                                        {t('package:btn.update_package')}
                                    </Button>
                                </li>
                            ) : null}

                            {lodash.get(packageData, 'permissions.can_delete') ? (
                                <li>
                                    <DeletePackageComponent packageData={packageData} />
                                </li>
                            ) : null}

                            {lodash.get(packageData, 'permissions.can_unlink_order') ? (
                                <li>
                                    <UnLinkComponent packageData={packageData} />
                                </li>
                            ) : null}
                        </ul>
                        <ul className="list-action list-action--right">
                            {
                                !!this.props.package.weight_net &&
                                <li>
                                    <CreateMultiSamePackage
                                        className={clsx({
                                            [`_btn_create_same_package ${classButton}`]: lodash.get(packageData, 'permissions.can_create_package'),
                                            'a-hidden': !lodash.get(packageData, 'permissions.can_create_package'),
                                        })}
                                        packageInfo={this.props.package}
                                        fetchBarcodePackagesAgain={this.props.fetchBarcodePackagesAgain}
                                    />
                                </li>
                            }
                            <li>
                                <Button
                                    className={clsx({
                                        [`_btn_create_same_package ${classButton}`]: lodash.get(
                                            packageData,
                                            'permissions.can_create_package'
                                        ),
                                        'a-hidden': !lodash.get(packageData, 'permissions.can_create_package'),
                                    })}
                                    onClick={this.createSamePackage.bind(this)}
                                >
                                    {t('package:create_same_code.btn_create')}
                                </Button>
                            </li>

                            {lodash.get(packageData, 'permissions.can_print_barcode') ? (
                                <li>
                                    <PrintPackageStampContainer
                                        disabledPrintStamp={disabledPrintStamp}
                                        numberStamp={4}
                                        packageData={packageData}
                                    />
                                </li>
                            ) : null}
                             {!!canPrintOfficialStamp && allowPrintOfficalStamp &&(
                                <li>
                                    <PrintOfficialStamp
                                        className="a-btn--button-link a-text--blue _btn_print_official_stamp"
                                        packageData={packageData}
                                    />
                                </li>
                            )}

                            {lodash.get(packageData, 'permissions.can_print_barcode') ? (
                                <>
                                    <li className="not-line-space">
                                        {disabledPrintStamp ? (
                                            <Button className={classButtonDisabled} disabled={disabledPrintStamp}>
                                                {t('package:btn.link_print')}
                                            </Button>
                                        ) : (
                                            <a
                                                className={'a-btn--button-link a-text--blue _link_print_stamp'}
                                                target="_blank"
                                                href={url.to('packages.link_stamp', { id: packageData.id })}
                                                rel="noopener noreferrer"
                                            >
                                                {t('package:btn.link_print')}
                                            </a>
                                        )}
                                    </li>
                                    <li>
                                        <PrintPackageStampContainer
                                            disabledPrintStamp={disabledPrintStamp}
                                            packageData={packageData}
                                            type="primary"
                                            numberStamp={1}
                                            onRef={this.handleRefPrintPackageStamp}
                                        />
                                    </li>
                                    {!!id_operate_package && (
                                        <li>
                                            <PrintPackageStampContainer
                                                disabledPrintStamp={disabledPrintStamp}
                                                numberStamp={1}
                                                packageData={packageData}
                                                operatePackage={true}
                                                type="primary"
                                            />
                                        </li>
                                    )}
                                </>
                            ) : null}
                            {/*{!!canPrintUnofficialStamp && (*/}
                            {/*    <li>*/}
                            {/*        <PrintUncheckStampButton*/}
                            {/*            className="_btn_print_official_stamp a-btn--primary ant-btn-primary"*/}
                            {/*            ghost={false}*/}
                            {/*            packageData={packageData}*/}
                            {/*        />*/}
                            {/*    </li>*/}
                            {/*)}*/}

                            {!!canPrintBatchStamp && (
                                <li>
                                    <PrintBatchStampButton
                                        className="a-btn--primary ant-btn-primary _print-batch-stamp"
                                        packageData={packageData}
                                    />
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </Form>
        );
    }
}

PackageDetail.defaultProps = {
    package: {},
    setPackagePage: () => {},
};

PackageDetail.propTypes = {
    package: PropTypes.object,
    setPackagePage: PropTypes.func,
};

export default withTranslation()(Form.create()(PackageDetail));
