import React, { Component } from 'react';
import { Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';

import ProductDetail from './PrintBagStamp/ProductDetail';
import ShipmentDetail from './PrintBagStamp/ShipmentDetail';

const { Paragraph, Text } = Typography;

class PrintBagStamp extends Component {
    componentDidMount() {
        this.props.onDidMount();
    }

    render() {
        const { bag, warehouse_input, product } = this.props;

        return (
            <div className="print-bag-stamp">
                <div className="a-flex">
                    <div className="width-50-pc mx-4">
                        <div className="a-flex">
                            <div className="width-35-pc">
                                <Paragraph className="mb-2">
                                    Created date:
                                </Paragraph>
                            </div>
                            <div className="width-65-pc">
                                <Paragraph className="mb-2">
                                    {moment(get(bag, 'created_at')).format('DD - MM - YYYY')}
                                </Paragraph>
                            </div>
                        </div>
                        <div className="a-flex">
                            <div className="width-35-pc">
                                <Paragraph className="mb-0">
                                    Export date:
                                </Paragraph>
                            </div>
                            <div className="width-65-pc">
                                <Paragraph className="mb-0">
                                    {moment().format('DD - MM - YYYY')}
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                    <div className="width-50-pc mx-4">
                        <Barcode
                            width={244 / (46 + (11 * (get(bag, 'code', '').length - 1)))}
                            height={80}
                            margin={0}
                            displayValue={false}
                            value={get(bag, 'code')}
                            copyStyles={true}
                        />
                    </div>
                </div>

                <div className="a-flex">
                    <div className="width-50-pc mx-4">
                    </div>
                    <div className="width-50-pc mx-4">
                        <div className="a-flex">
                            <div className="width-35-pc">
                                <Paragraph className="mb-2">
                                    Bag no:
                                </Paragraph>
                            </div>
                            <div className="width-65-pc">
                                <Paragraph className="mb-2">
                                    {get(bag, 'code')}
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="a-flex">
                    <div className="a-flex galign-center gjustify-center tile width-25-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            From
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        <Paragraph className="mb-2">
                            Name: {get(warehouse_input, 'name')}
                        </Paragraph>
                        <Paragraph className="mb-2 a-text--bold">
                            Mobile:
                        </Paragraph>
                        <Paragraph className="mb-0">
                            Add:
                        </Paragraph>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="a-flex galign-center gjustify-center tile width-25-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            To
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        <Paragraph className="a-text--fz-18 mb-0">
                            {get(bag, 'receiver_name')}
                        </Paragraph>
                    </div>
                </div>

                <hr />

                <ShipmentDetail bag={bag} />

                <ProductDetail product={product} />

                <div className="a-flex gjustify-space-between mx-8 mb-8">
                    <div className="a-flex galign-center gjustify-center gflex-direction-column a-text--center mx-8 mb-8">
                        <Text className="a-text--fz-14">Payment COD:</Text>
                    </div>
                    <div className="a-flex galign-center gjustify-center gflex-direction-column a-text--center mx-8 mb-8">
                        <Text>Receiver's signature</Text>
                        <Text>(Confirm intact goods)</Text>
                    </div>
                </div>
            </div>
        );
    }
}

PrintBagStamp.defaultProps = {
    onDidMount: () => {}
};

PrintBagStamp.propTypes = {
    onDidMount: PropTypes.func
};

export default PrintBagStamp;
