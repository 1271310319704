import React from 'react';
import { Modal, notification } from 'antd';
import { get, has, hasIn, } from 'lodash';

import apiService from './apiService';
import { CREATE, CREATE_WITH_TRACKING_NO, GET_DELIVERY_CUSTOMER_REQUESTS } from './constants';
import { DELIVERY_OBJECT } from './../constants'
import { BARCODE_TYPE } from './../DeliveryNoteBarcode/constants';
import { processApiRequest } from './../../Common/saga';
import deliveryRequestApiService from './../../DeliveryRequest/ListDeliveryRequest/apiService';
import { trans } from './../../../system/i18n';
import { url } from '../../../system/routing';
import apiDeliveryNote from '../apiService';

export function* create(action) {
    yield processApiRequest(
        CREATE,
        () => apiService.create(action.payload),
        action.payload
    );
}

export function* successCreate(action) {
    yield notification.success({
        message: trans('delivery_note:message.create_delivery_note_success')
    });

    if (has(action, 'payload.warnings.warning_lack_of_package')) {
        yield setTimeout(() => {
            notification.warning({
                message: (
                    <span>
                        {trans('delivery_note:message.warning_lack_of_package', {
                            delivery_note: (
                                <b>{get(action, 'payload.warnings.warning_lack_of_package').toString()}</b>
                            )
                        })}
                    </span>
                )
            });
        }, 500)
    }
}

export function* failedCreate(action) {
    const error = get(action, 'payload.data');
    if (get(error, 'warehouse')) {
        return yield notification.error({
            message: <span>{trans('message.warehouse_not_is_staff')}</span>,
        });
    }

    if (hasIn(error, 'package.package_not_same_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_same_delivery_request')}</span>,
        });
    }

    if (hasIn(error, 'package.package_all_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_all_not_in_delivery_request')}</span>,
        });
    }

    if (hasIn(error, 'package.package_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_in_delivery_request')}</span>,
        });
    }

    if (get(error, 'package.message') === 'package_in_open_delivery_note') {
        const packages = get(error, 'package.packages', [])
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_in_open_delivery_note', {packages: packages.join(", ")})}</span>,
        });
    }


    if (hasIn(error, "id_last_mile_carrier.required")) {
        return yield notification.error({
            message: <span>{trans('validation:required', {attribute: trans('shipping_partner')})}</span>,
        });
    }

    yield notification.error({
        message: trans('delivery_note:message.create_delivery_note_failed')
    });
}

export function* succcessScanBarcode(action)  {
    const { payload, request } = action;

    if (get(request, 'params.type') === DELIVERY_OBJECT.RETURN || get(payload, 'customer.id')) {
        return yield;
    }

    return yield notification.error({
        message: trans('scan:error.not_belong_to_any_customer', {
            type: trans((payload.bag ? BARCODE_TYPE.BAG : BARCODE_TYPE.PACKAGE).toLowerCase())
        })
    });
}

export function* getDeliveryCustomerRequests(action) {
    return yield processApiRequest(
        GET_DELIVERY_CUSTOMER_REQUESTS,
        () => deliveryRequestApiService.getDeliveryCustomerRequests(action.payload),
        action.payload
    );
}

export function handleConfirm(deliveryNoteId, idLastmileShipment, isConfirm = true ) {
    apiService.createTrackingNoWithCodIsZero(deliveryNoteId, {"id_last_mile_shipment": idLastmileShipment, "create_with_cod_is_zero": isConfirm})
    .then(() => {
        notification.success({
            message: trans('delivery_note:message.create_delivery_note_success')
        });
        if (isConfirm) {
            // url.redirectTo('delivery-notes.customer.detail', {
            //     id: deliveryNoteId
            // });
            window.open(`/delivery-notes/customer/${deliveryNoteId}`, "_blank")
            handlePrintLastmileStamp(deliveryNoteId, idLastmileShipment)
        } else {
            // url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
            //     id: deliveryNoteId,
            // })
            window.open(`/delivery-notes/customer/domestic-shipping-orders/${deliveryNoteId}`, '_blank');   
        }    
    }).catch(() => {
        if (isConfirm) {
            notification.error({
                message: trans('delivery_note:message.create_tracking_failed')
            });
        }

        // url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
        //     id: deliveryNoteId,
        // })
        window.open(`/delivery-notes/customer/domestic-shipping-orders/${deliveryNoteId}`, '_blank');
    })
}


export async function handlePrintLastmileStamp(deliveryNoteId, orderId ) {
    try {
        const { data } = await apiDeliveryNote.printLastMileOrder(deliveryNoteId, orderId);

        window.open(data, '_blank');
    } catch (error) {
        notification.error({
            message: trans('delivery_note:print_last_mile_order.failed')
        });
    }
}

export function* createWithTrackingNo(action) {
    yield processApiRequest(
        CREATE_WITH_TRACKING_NO,
        () => apiService.createWithTrackingNo(action.payload),
        action.payload
    );
}

export function* successCreateWithTrackingNo(action) {
    const payload = get(action, "payload", {})
    const { error, warning, delivery_note, last_mile_shipment } = payload;
    const deliveryNoteId = get(delivery_note, 'id', 0);
    const idLastmileShipment = get(last_mile_shipment, "id", 0)
    if (error || warning){
        if (error) {
            if (error === 'create_delivery_note_failed') {
                yield notification.error({
                    message: trans('delivery_note:message.create_delivery_note_failed')
                });
            }

            if (error === 'delivery_note_must_have_one_delivery_request') {
                yield notification.error({
                    message: trans('delivery_note:message.delivery_note_must_have_one_delivery_request')
                });
            }

            if (error === 'delivery_note_has_many_destination') {
                yield notification.error({
                    message: trans('delivery_note:message.delivery_note_has_many_destination')
                });
            }

            if (error === 'create_tracking_failed') {
                yield notification.error({
                    message: trans('delivery_note:message.create_tracking_failed')
                });
            }

            if (deliveryNoteId) {
                // url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
                //     id: deliveryNoteId
                // });
                window.open(`/delivery-notes/customer/domestic-shipping-orders/${deliveryNoteId}`, '_blank');
            }
        }
        if (warning) {
            yield Modal.confirm({
                title: trans("delivery_note:title.confirm_create_lastmile_shipment"),
                content: (
                    <p>
                        {trans("delivery_note:message.confirm_create_lastmile_shipment_with_code_is_zero")}
                    </p>
                ),
                okText: trans('btn.confirm'),
                cancelText: trans('btn.cancel'),
                onOk: () => handleConfirm(deliveryNoteId, idLastmileShipment),
                onCancel: () => handleConfirm(deliveryNoteId, idLastmileShipment, false),
            })
        }
        
    } else {
        yield notification.success({
            message: trans('delivery_note:message.create_delivery_note_success')
        });
        if (deliveryNoteId) {
            // url.redirectTo('delivery-notes.customer.detail', {
            //     id: deliveryNoteId
            // });
            window.open(`/delivery-notes/customer/${deliveryNoteId}`, '_blank');

            handlePrintLastmileStamp(deliveryNoteId, idLastmileShipment)
        }  
    }
}


