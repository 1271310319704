import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { printOfficialPackageStamp } from '../../Common/PrintStamp/actions';
import { Button, Modal, notification } from 'antd';
import { t } from '../../../system/i18n';
import apiService from '../apiService';

const mapStateToProps = ({ common }) => {
    const { loadingPrintTemplate } = common;

    return {
        loadingPrintTemplate,
    };
};

const mapDispatchToProps = dispatch => ({
    printOfficialPackageStamp: data => {
        dispatch(printOfficialPackageStamp(data));
    },
});

class PrintTAPStampButton extends Component {
    state = {
        loading: false,
        modal: undefined,
    };

    handlePrintOfficialStampButtonClick = () => {
        const { confirmBeforePrint, confirmTitle } = this.props;

        if (confirmBeforePrint) {
            this.setState({
                modal: Modal.confirm({
                    cancelText: t('btn.cancel'),
                    okText: t('btn.ok'),
                    title: confirmTitle,
                    onOk: this.printOfficialStamp,
                }),
            });
        } else {
            this.printOfficialStamp();
        }
    };

    printOfficialStamp = async () => {
        const { modal } = this.state;
        const { packageData } = this.props;

        if (modal) {
            modal.destroy();
        }

        this.setState({
            loading: true,
        });

        try {
            const response = await apiService.getPackage(lodash.get(packageData, 'code'));
            const data = lodash.get(response, 'data');
            const printedOfficialStamp = lodash.get(data, 'package.package_detail.printed_official_stamp');

            if (data) {
                if (printedOfficialStamp) {
                    Modal.confirm({
                        cancelText: t('btn.cancel'),
                        okText: t('btn.ok'),
                        title: t('package:printed_confirm'),
                        onOk: this.doPrintOfficialStamp,
                    });
                } else {
                    this.doPrintOfficialStamp(data);
                }
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                });
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            });
        }

        this.setState({
            loading: false,
        });
    };

    doPrintOfficialStamp = async () => {
        const { packageData, printOfficialPackageStamp } = this.props;

        this.setState({
            loading: true,
        });

        try {
            const response = await apiService.getVTAndTAPStampData(lodash.get(packageData, 'code'), { temp: 'tap' });
            const data = lodash.get(response, 'data');

            if (data) {
                setTimeout(() => {
                    printOfficialPackageStamp({ ...data });
                }, 1500);
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                });
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            });
        }

        this.setState({
            loading: false,
        });
    };

    render() {
        const { loading } = this.state;
        const { className, ghost, loadingPrintTemplate, packageData } = this.props;

        if (!lodash.get(packageData, 'permissions.can_print_unofficial_stamp')) {
            return null;
        }
        // if (!lodash.get(packageData, 'is_checked')) {
        //     return null;
        // }

        return (
            <Button
                className={className}
                ghost={ghost}
                loading={loading || loadingPrintTemplate}
                onClick={this.handlePrintOfficialStampButtonClick}
            >
                {t('package:btn.print_tap')}
            </Button>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintTAPStampButton);
