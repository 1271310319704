import React, {Component} from 'react';
import {Typography} from 'antd';
import clsx from 'clsx';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import {connect} from 'react-redux';

import {currentFormatter} from './../../services/format';
import {finishLoadPrintTemplate, loadPrintTemplate} from './../actions';

import ProductDetail from './PrintOfficialPackageStamp/ProductDetail';
import ShipmentDetail from './PrintOfficialPackageStamp/ShipmentDetail';

const { Paragraph, Text } = Typography;

class PrintOfficialPackageStamp extends Component {
    constructor(props) {
        super();

        props.loadPrintTemplate();
    }

    handleDidMount = () => {
        const {finishLoadPrintTemplate, onDidMount} = this.props;

        finishLoadPrintTemplate();
        onDidMount();
    };

    componentDidMount() {
        const {logo} = this.props;
        const packageData = this.props.package;
        const isBatch = get(packageData, 'is_batch', false);

        if (isBatch || !logo) {
            this.handleDidMount();
        }
    }

    render() {
        console.log("props", this.props)
        const {package_stt,estimate_package_total, manifests, destination_warehouse, logo, max_value, shipping_partner, products, product_quantity, total_value, order, services, properties, warehouse_services} = this.props;
        const packageData = this.props.package;
        const isBatch = get(packageData, 'is_batch', false);

        let totalAmount = total_value > 0 && total_value < max_value ? total_value : null;
        if (max_value === null) {
            //nếu không cấu hình thì hiển thị đúng giá trị
            totalAmount = total_value;
        }
        if (max_value == 0) {
            //Có thể giá trị = 0, khi giá trị cấu hình là 0 thì auto không hiển thị giá trị này
            totalAmount = null
        }
        let fromName    = get(manifests, 'custom_delegation_name');
        let fromMobile  = get(manifests, 'custom_delegation_tel');
        let fromAddress = get(manifests, 'custom_delegation_address');
        let toName      = get(manifests, 'customer_receive_name');
        let toMobile    = get(manifests, 'customer_receive_phone');
        let toAddress   = get(manifests, 'customer_receive_address');
        let showSenderEcommerceStamps = get(manifests, 'show_sender_ecommerce_stamps');

        if (!fromName && get(shipping_partner, 'name')) {
            fromName = get(shipping_partner, 'name');
        }
        if (!fromMobile && get(shipping_partner, 'phone')) {
            fromMobile = get(shipping_partner, 'phone');
        }
        if (!fromAddress && get(shipping_partner, 'address')) {
            fromAddress = get(shipping_partner, 'address');
        }

        return (
            <div className="print-package-official-stamp print-package-official-stamp-custom">
                <div className="a-flex a-text--nowrap">
                    {isBatch ? (
                        null
                    ) : (
                        <div className="a-flex width-45-pc mx-4">
                            {logo ? (
                                <img
                                    alt=""
                                    className="partner-logo"
                                    src={logo}
                                    onLoad={this.handleDidMount}
                                    onError={this.handleDidMount}
                                />
                            ) : null}
                        </div>
                    )}
                    <div className={clsx('mx-4 a-text--pre-wrap', {
                        'width-100-pc': isBatch,
                        'width-60-pc order-no': !isBatch
                    })}>
                        <div className="a-flex">
                            <div className={clsx({
                                'width-20-pc': isBatch,
                                'width-30-pc': !isBatch
                            })}>
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    {destination_warehouse}
                                </Paragraph>
                            </div>
                            <div className={clsx({
                                'width-60-pc': isBatch,
                                'width-80-pc': !isBatch
                            })}>
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    Order no: <Text className="a-text--fz-24">{get(order, 'code')}</Text>
                                </Paragraph>
                            </div>
                            {isBatch ? (
                                <div className={clsx({
                                    'width-30-pc': isBatch
                                })}>
                                    <Paragraph className="mb-2 a-text--fz-16" strong>
                                        {get(packageData, 'ref_shipment_code')}
                                    </Paragraph>
                                </div>
                            ) : null}
                        </div>
                        <div className="a-flex">
                            <div className={clsx({
                                'width-20-pc': isBatch,
                                'width-30-pc': !isBatch
                            })}>
                                <Paragraph className="mb-2 mt-1 a-text--fz-16" strong>
                                    {get(order, 'id')}
                                </Paragraph>
                            </div>
                            <div className={clsx({
                                'width-60-pc': isBatch,
                                'width-80-pc': !isBatch
                            })}>
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    User: <Text
                                    className="a-text--fz-24 a-text--uppercase">{get(order, 'customer_username') || get(packageData, 'customer_username')}</Text>
                                </Paragraph>
                            </div>
                            {isBatch ? (
                                <div className={clsx({
                                    'width-30-pc': isBatch
                                })}>
                                    <Paragraph className="mb-2 mt-1 a-text--fz-16" strong>
                                        {get(packageData, 'ref_customer_code')}
                                    </Paragraph>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="a-flex g-justify-center galign-end border-bottom pb-4 ">
                    <Barcode
                        copyStyles={true}
                        height={80}
                        margin={0}
                        value={get(packageData, 'code')}
                        width={3.5}
                        fontSize={28}
                        fontOptions={"bold"}
                        font={'Roboto, sans-serif'}
                        textMargin={10}
                    />
                    {!!(estimate_package_total && package_stt && estimate_package_total > 0) && (
                        <div className="stt">{`${package_stt}/${estimate_package_total}`}</div>
                    )}
                </div>

                <div className="row border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            From
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        {showSenderEcommerceStamps && (
                            <div>
                                <Paragraph className="mb-0">
                                    Name: {fromName}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                    Mobile: {fromMobile}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                    Add: {fromAddress}
                                </Paragraph>
                            </div>
                        )}

                    </div>
                </div>

                <div className="a-flex border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            To
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        {(toName || toMobile || toAddress) && (
                            <div>
                                <Paragraph className="mb-0">
                                    Name: {toName}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                   Mobile: {toMobile}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                    Add: {toAddress}
                                </Paragraph>
                            </div>
                        ) }
                    </div>
                </div>

                <ShipmentDetail
                    packageData={packageData}
                    productQuantity={product_quantity}
                    services={services}
                    properties={properties}
                    warehouse_services={warehouse_services}
                />

                <ProductDetail products={products} />

                <div className="row">
                    <div className="a-flex galign-center gjustify-center gflex-direction-column a-text--center tile width-25-pc">
                        <Text className="a-text--fz-14">Payment COD:</Text>
                        <Text className="a-text--fz-16" strong>
                            {totalAmount ? currentFormatter.toLocaleStringCurrency(totalAmount)+' VNĐ' : 'VNĐ'}
                        </Text>
                    </div>
                    <div className="a-flex gjustify-center gflex-direction-column tile width-75-pc">
                        <Text>Receiver's signature</Text>
                        <Text>(Confirm intact goods)</Text>
                    </div>
                </div>
            </div>
        );
    }
}

PrintOfficialPackageStamp.defaultProps = {
    onDidMount: () => {}
};

PrintOfficialPackageStamp.propTypes = {
    onDidMount: PropTypes.func
};

export default connect(
    undefined,
    {
        finishLoadPrintTemplate,
        loadPrintTemplate
    },
)(PrintOfficialPackageStamp);
