import React, { Component } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, notification, Row, Switch } from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from './../../DetailShippingPartner/apiServices';
import { formatCurrency, replaceText } from './../../../Common/services/helps';

import LocationShippingPartnerFormContainer from './../../../Location/LocationShippingPartnerForm/LocationShippingPartnerFormContainer';
import { url } from './../../../../system/routing';

const { TextArea, Password } = Input;
const { confirm } = Modal;
class FormShippingPartner extends Component {
    state = {
        shippingPartner: {}
    };

    fetchDetailShippingPartner = async id => {
        const { form, match: { params: { type } } } = this.props;

        this.setState({
            loading: true
        });

        try {
            const { data } = await api.fetchDetailShippingPartner(id);

            const { setCurrentCity, setCurrentDistrict } = this.props;
            const formData = {};
            formData.id = lodash.get(data, 'shipping_partner.id');
            formData.name = lodash.get(data, 'shipping_partner.name');
            formData.code = lodash.get(data, 'shipping_partner.code');
            formData.phone = lodash.get(data, 'shipping_partner.phone');
            formData.email = lodash.get(data, 'shipping_partner.email');
            formData.address = lodash.get(data, 'shipping_partner.address');
            formData.note = lodash.get(data, 'shipping_partner.note');
            formData.max_value_package = lodash.get(data, 'shipping_partner.max_value_package');
            formData.max_weight_package = lodash.get(data, 'shipping_partner.max_weight_package');
            formData.is_default_shipping_partner = lodash.get(data, 'shipping_partner.is_default_shipping_partner');
            formData.active = lodash.get(data, 'shipping_partner.active');
            const type = lodash.get(data, 'location.type');
            const location = {};

            switch (type) {
                case 'WARD': {
                    location.id_country = lodash.get(data, 'location.parent.parent.parent.id');
                    location.id_city = lodash.get(data, 'location.parent.parent.id');
                    location.id_district = lodash.get(data, 'location.parent.id');
                    location.id_ward = lodash.get(data, 'shipping_partner.id_location');
                    break;
                }
                case 'DISTRICT': {
                    location.id_country = lodash.get(data, 'location.parent.parent.id');
                    location.id_city = lodash.get(data, 'location.parent.id');
                    location.id_district = lodash.get(data, 'shipping_partner.id_location');
                    break;
                }
                default: {
                    location.id_country = undefined;
                    location.id_city = undefined;
                    location.id_district = undefined;
                    location.id_ward = undefined;
                }
            }

            form.setFieldsValue(formData);

            this.setState({
                shippingPartner: data.shipping_partner,
                idCountry: location.id_country,
                idCity: location.id_city,
                idDistrict: location.id_district,
                idWard: location.id_ward
            });

            setCurrentCity(formData.id_city);
            setCurrentDistrict(formData.id_district);
        } catch (error) {
            notification.error({
                message: this.props.t('shipping_partner:message.error_get_detail_shipping_partner')
            });

            setTimeout(() => {
                url.redirectTo('config.shipping-partners.list', {
                    type
                });
            }, 500);
        }

        this.setState({
            loading: false
        });
    };

    fetchDetailShippingDomesticPartner = async id => {
        const { form, match: { params: { type } } } = this.props;

        this.setState({
            loading: true
        });

        try {
            const { data } = await api.fetchDetailShippingDomesticPartner(id);

            const formData = {};
            formData.id = lodash.get(data, 'shipping_domestic_partner.id');
            formData.name = lodash.get(data, 'shipping_domestic_partner.name');
            formData.hotline = lodash.get(data, 'shipping_domestic_partner.hotline');
            formData.phone = lodash.get(data, 'shipping_domestic_partner.phone');
            formData.access_token = lodash.get(data, 'shipping_domestic_partner.access_token');
            formData.active = lodash.get(data, 'shipping_domestic_partner.active');

            form.setFieldsValue(formData);

            this.setState({
                shippingPartner: data.shipping_domestic_partner
            });
        } catch (error) {
            notification.error({
                message: this.props.t('shipping_partner:message.error_get_detail_shipping_partner')
            });

            setTimeout(() => {
                url.redirectTo('config.shipping-partners.list', {
                    type
                });
            }, 500);
        }

        this.setState({
            loading: false
        });
    };

    handleSubmit = event => {
        const { form, createShippingPartner, editShippingPartner } = this.props;

        event.preventDefault();

        form.validateFields((error, values) => {
            if (error) {
                return;
            }

            const formattedValues = lodash.omit(values, [
                'id_country',
                'id_city',
                'id_district',
                'id_ward'
            ]);

            if (values.id_ward) {
                formattedValues.id_location = values.id_ward;
            } else if (values.id_district) {
                formattedValues.id_location = values.id_district;
            } else if (values.id_city) {
                formattedValues.id_location = values.id_city;
            } else if (values.id_country) {
                formattedValues.id_location = values.id_country;
            } else {
                formattedValues.id_location = null;
            }

            if (!values.id) {
                createShippingPartner(formattedValues);
            } else {
                editShippingPartner(formattedValues);
            }
        });
    };

    onCancel = () => {
        this.props.history.goBack();
    };

    changeTextValue = event => {
        const { errors, resetError } = this.props;
        const { name } = event.target;

        if (lodash.has(errors, name)) {
            resetError(name);
        }
    };

    changeNumber = (name, value) => {
        const { errors, resetError } = this.props;

        if (lodash.has(errors, name)) {
            resetError(name);
        }
    }

    onConfirmChangeActive = checked => {
        this.setState({
            active: checked
        })
    };

    handleChangeLocation = location => {
        this.setState({
            idCountry: location.id_country,
            idCity: location.id_city,
            idDistrict: location.id_district,
            idWard: location.id_ward
        });
    };

    changeActiveValue = checked => {
        const { form, t } = this.props;

        if (form.getFieldValue('is_default_shipping_partner')) {
            confirm({
                title: t('shipping_partner:label.title_confirm_disable_shipping_partner'),
                onOk: this.onConfirmChangeActive.bind(this, checked),
                okButtonProps: {
                    className: '_btn-ok'
                },
                onCancel: this.onConfirmChangeActive.bind(this, !checked),
                cancelButtonProps: {
                    className: '_btn-cancel'
                }
            });
        }
    };

    renderInternationalForm = () => {
        const { idCity, idCountry, idDistrict, idWard, shippingPartner } = this.state;
        const { errors, form, t } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        return (
            <>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.name}
                        label={t('shipping_partner:label.name')}
                        labelCol={{span: 24}}
                        validateStatus={errors.name ? 'error' : undefined}
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('shipping_partner:label.name')
                                }),
                                required: true
                            }]  
                        })(
                            <Input
                                className="a-input _a-input-name"
                                name="name"
                                placeholder={t("shipping_partner:placeholder.name")}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.code}
                        label={t('shipping_partner:label.code')}
                        labelCol={{span: 24}}
                        validateStatus={errors.code ? 'error' : undefined}
                    >
                        {getFieldDecorator('code', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('shipping_partner:label.code')
                                }),
                                required: true
                            }]  
                        })(
                            <Input
                                className="a-input __a-input-code"
                                name="code"
                                disabled={!!getFieldValue('id')}
                                placeholder={t("shipping_partner:placeholder.code")}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.phone}
                        label={t('shipping_partner:label.phone')}
                        labelCol={{span: 24}}
                        validateStatus={errors.phone ? 'error' : undefined}
                    >
                        {getFieldDecorator('phone', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('shipping_partner:label.phone')
                                }),
                                required: true
                            }]  
                        })(
                            <Input
                                className="a-input _a-input-phone"
                                name="phone"
                                placeholder={t('shipping_partner:placeholder.phone')}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.email}
                        label={t('shipping_partner:label.email')}
                        labelCol={{span: 24}}
                        validateStatus={errors.email ? 'error' : undefined}
                    >
                        {getFieldDecorator('email', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('shipping_partner:label.email')
                                }),
                                required: true
                            }]  
                        })(
                            <Input
                                className={"a-input _a-input-email"}
                                name="email"
                                placeholder={t('shipping_partner:placeholder.email')}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>

                <LocationShippingPartnerFormContainer
                    errors={errors}
                    form={form}
                    idCity={idCity}
                    idCountry={idCountry}
                    idDistrict={idDistrict}
                    idWard={idWard}
                    key={shippingPartner.id}
                    ward={form.getFieldValue('id_ward')}
                    onChangeLocation={this.handleChangeLocation}
                    resetError={this.props.resetError}
                />

                <Col className="a-form--group" lg={{span: 12}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.address}
                        label={t('shipping_partner:label.address')}
                        labelCol={{span: 24}}
                        validateStatus={errors.address ? 'error' : undefined}
                    >
                        {getFieldDecorator('address', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('shipping_partner:label.address')
                                }),
                                required: true
                            }]  
                        })(
                            <TextArea
                                autosize={{minRows: 1, maxRows: 3}}
                                className="a-input--textarea _a-input-address"
                                name="address"
                                placeholder={t('shipping_partner:placeholder.address')}
                                spellCheck={false}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 12}}>
                    <Form.Item
                        className="a-form__item"
                        label={t('shipping_partner:label.note')}
                        labelCol={{span: 24}}
                    >
                        {getFieldDecorator('note')(
                            <TextArea
                                autosize={{minRows: 1, maxRows: 3}}
                                className="a-input--textarea _a-input-note"
                                name="note"
                                placeholder={t('shipping_partner:placeholder.note')}
                                spellCheck={false}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col className="a-form--group" lg={{span: 12}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.max_value_package}
                        label={t('shipping_partner:label.max_value_package')}
                        labelCol={{span: 24}}
                        validateStatus={errors.max_value_package ? 'error' : undefined}
                    >
                        {getFieldDecorator('max_value_package')(
                            <InputNumber
                                className="a-input--textarea _a-input-max-total-value-product-per-package width-100-pc"
                                formatter={value => formatCurrency(value)}
                                name="max_value_package"
                                parser={value => replaceText(value)}
                                placeholder={t('shipping_partner:placeholder.max_value_package')}
                                spellCheck={false}
                                onChange={this.changeNumber.bind(this, 'max_value_package')}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 12}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.max_weight_package}
                        label={t('shipping_partner:label.max_weight_package')}
                        labelCol={{span: 24}}
                        validateStatus={errors.max_weight_package ? 'error' : undefined}
                    >
                        {getFieldDecorator('max_weight_package')(
                            <InputNumber
                                className="a-input--textarea _a-input-max-total-weight-per-package width-100-pc"
                                formatter={value => formatCurrency(value)}
                                name="max_weight_package"
                                parser={value => replaceText(value)}
                                placeholder={t('shipping_partner:placeholder.max_weight_package')}
                                spellCheck={false}
                                onChange={this.changeNumber.bind(this, 'max_weight_package')}
                            />
                        )}
                    </Form.Item>
                </Col>
            </>
        );
    };

    renderDomesticForm = () => {
        const { errors, form, t } = this.props;
        const { getFieldDecorator } = form;

        return (
            <>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.name}
                        label={t('shipping_partner:label.name')}
                        labelCol={{span: 24}}
                        validateStatus={errors.name ? 'error' : undefined}
                    >
                        {getFieldDecorator('name')(
                            <Input
                                className="a-input _a-input-name"
                                disabled
                                name="name"
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.hotline}
                        label="Hotline"
                        labelCol={{span: 24}}
                        validateStatus={errors.hotline ? 'error' : undefined}
                    >
                        {getFieldDecorator('hotline')(
                            <Input
                                className="a-input __a-input-hotline"
                                disabled
                                name="hotline"
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.phone}
                        label={t('shipping_partner:label.phone')}
                        labelCol={{span: 24}}
                        validateStatus={errors.phone ? 'error' : undefined}
                    >
                        {getFieldDecorator('phone')(
                            <Input
                                className="a-input _a-input-phone"
                                disabled
                                name="phone"
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col className="a-form--group" lg={{span: 12}}>
                    <Form.Item
                        className="a-form__item"
                        help={errors.access_token}
                        label="Access token"
                        labelCol={{span: 24}}
                        validateStatus={errors.access_token ? 'error' : undefined}
                    >
                        {getFieldDecorator('access_token', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: 'Access token'
                                }),
                                required: true
                            }]  
                        })(
                            <Password
                                className="a-input _a-input-access-token"
                                name="access_token"
                                placeholder={t('input.access_token')}
                                onChange={this.changeTextValue}
                            />
                        )}
                    </Form.Item>
                </Col>
            </>
        );
    };

    componentDidMount() {
        const { match: { params: { id, type } } } = this.props;

        if (id) {
            if (type === 'international') {
                this.fetchDetailShippingPartner(id);
            }

            if (type === 'domestic') {
                this.fetchDetailShippingDomesticPartner(id);
            }
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        const { shippingPartner } = this.state;
        const { form, isLoadingLocation, match: { params: { type } }, t } = this.props;
        const loading = this.state.loading || this.props.loading;
        const { getFieldDecorator, getFieldValue } = form;

        return (
            <Form className="a-block-config amt-16 a-form" onSubmit={this.handleSubmit}>
                <div className="a-block-config__title gflex gjustify-space-between">
                    <h3 className="title">{t('shipping_partner:label.info')}</h3>
                    <div>
                        <label className="a-text--fz-12 a-text--gray mr-2">
                            {t('shipping_partner:label.status')}
                        </label>
                        {getFieldDecorator('active', {
                            defaultValue: true
                        })(
                            <Switch
                                checked={getFieldValue('active')}
                                className="_switch-active"
                                size="small"
                                onChange={this.changeActiveValue}
                            />
                        )}
                    </div>
                </div>
                <div className="a-block-config__body">
                    <Row className="a-block-info" gutter={{lg: 24, xl: 32}} type="flex">
                        <Col className="a-form--group a-hidden">
                            <Form.Item className="a-form__item">
                                {getFieldDecorator('id')(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        {type === 'international' && this.renderInternationalForm()}
                        {type === 'domestic' && this.renderDomesticForm()}
                    </Row>

                    <Row gutter={48}>
                        <Col lg={{span: 24}}
                                className="a-package--action only-action">
                            <Button
                                className="a-btn a-btn--back-package _btn-cancel"
                                onClick={this.onCancel}
                            >
                                {t('btn.cancel')}
                            </Button>
                            <Button
                                className="a-btn a-btn--primary a-btn--save-package _btn-save"
                                htmlType="submit"
                                loading={loading || isLoadingLocation}
                                type="primary"
                            >
                                {shippingPartner.id ? t('btn.save') : t('btn.add')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    }
}

FormShippingPartner.defaultProps = {
    errors: {},
    loading: false,
    countries: [],
    cities: [],
    district: [],
    wards: [],
    isLoadingLocation: false,
    shippingPartnerDetail: {}
};

FormShippingPartner.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    countries: PropTypes.array,
    cities: PropTypes.array,
    district: PropTypes.array,
    wards: PropTypes.array,
    isLoadingLocation: PropTypes.bool,
    shippingPartnerDetail: PropTypes.object
};

export default withTranslation()(Form.create()(FormShippingPartner));
