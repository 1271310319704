import React, { useState } from 'react'
import { Modal, Checkbox, Button, notification, Form, InputNumber } from 'antd'
import { withTranslation } from 'react-i18next'
import { MAXIMUM_COUNT_WAIT_INTERVAL } from '../constants'
import { get, chunk } from 'lodash'
import apiService from '../apiService'
import { dispatch } from '../../../system/store'
import { printMultiPackageStamp } from '../../Common/PrintStamp/actions'

const CreateMultiSamePackage = ({ className, t, form, packageInfo, fetchBarcodePackagesAgain }) => {
    const { getFieldDecorator } = form
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [intervalOld, setIntervalOld] = useState(0)

    const handleCancel = async () => {
        setVisible(false)
        setLoading(false)
        form.resetFields()
        clearInterval(intervalOld)
    }

    const handleOk = () => {
        setLoading(true)
        form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                setLoading(false)
                return
            }

            apiService
                .createPackageSameCode({ ...packageInfo, id_warehouse: get(packageInfo, 'id_warehouse_current', ''), number: values['amount'] })
                .then(res => {
                    const taskId = get(res, 'data.task_id', '')
                    handleInterval(taskId)
                })
                .catch(err => {
                    handleCancel()
                    notification.error({ message: t('message.server_error') })
                })
        })
    }

    const handleInterval = taskId => {
        let stt = 0
        const totalPackage = form.getFieldValue('amount')
        const interval = setInterval(async () => {
            clearInterval(intervalOld)
            stt++
            let totalCreated = 0
            try {
                const res = await apiService.handleProcessTaskId(taskId)
                totalCreated = get(res, 'data.task.total_created', 0)
                if (totalCreated === totalPackage) {
                    clearInterval(interval)
                    setLoading(false)
                    notification.success({
                        message: t('package:create_same_code.message_create_package_success'),
                    })
                    setVisible(false)
                    setTimeout(async () => {
                        if (form.getFieldValue('auto_print_stamp')) {
                            await doPrintStampAuto(taskId)
                        }
                        await fetchBarcodePackagesAgain({ page: 1 })
                        await handleCancel()
                    }, 1000)
                }
            
            } catch (error) {
                clearInterval(interval)
                handleCancel()
                notification.error({
                    message: t('package:create_same_code.message_create_package_failed', {totalCreated, totalPackage}),
                })
            }
            if (stt >= MAXIMUM_COUNT_WAIT_INTERVAL) {
                setLoading(false)
                clearInterval(interval)
                handleCancel()
                notification.error({
                    message: t('package:create_same_code.over_time_allow', {totalCreated, totalPackage}),
                })
            }
        }, 8000)
        setIntervalOld(interval)
    }

    const doPrintStampAuto = async taskId => {
        const originPackageId = get(packageInfo, 'id')
        const permissions = get(packageInfo, 'permissions', {})
        try {
            const response = await apiService.getInfoPrintStampMultiSamePackage(originPackageId, taskId)
            let data = get(response, 'data.data', {})
            let warehouseCode = get(data, 'origin_package.destination_warehouse.code', '')
            const items = get(response, 'data.items', [])
            chunk(items, 50).map(item => {
                const newData = {
                    packageData: {
                        ...data,
                        ...get(data, 'origin_package', {}),
                        permissions
                    },
                    warehouseCode,
                    items: item,
                }
                dispatch(printMultiPackageStamp(newData))
            })
        } catch (error) {
            console.log('lỗi in tem')
        }
    }

    return (
        <>
            <Button
                className={className}
                onClick={() => setVisible(true)}>
                {t('package:create_same_code.btn_multi_create')}
            </Button>
            <Modal
                title="Tạo kiện tương tự theo số lượng"
                visible={visible}
                okButtonProps={{ loading }}
                cancelButtonProps={{ disabled: loading }}
                onCancel={handleCancel}
                onOk={handleOk}
                okText={t('btn.confirm')}
                cancelText={t('btn.cancel')}>
                <Form.Item
                    label={t('package:label.amount_multi_create')}
                    className="mb-0">
                    {getFieldDecorator('amount', {
                        rules: [
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('package:label.amount_multi_create'),
                                }),
                            },
                            {
                                validator: async (rule, value, callback) => {
                                    if (value !== '') {
                                        if (Number.isInteger(value)) {
                                            if (value <= 1) {
                                                callback(
                                                    t('validation:gt', {
                                                        attribute: t('package:label.amount_multi_create'),
                                                        value: 1,
                                                    })
                                                )
                                            }
                                            if (value > 500) {
                                                callback(
                                                    t('validation:lte', {
                                                        attribute: t('package:label.amount_multi_create'),
                                                        value: 500,
                                                    })
                                                )
                                            }
                                        } else {
                                            callback(
                                                t('validation:numeric', {
                                                    attribute: t('package:label.amount_multi_create'),
                                                })
                                            )
                                        }
                                    }
                                },
                            },
                        ],
                    })(
                        <InputNumber
                            style={{ width: '100%' }}
                            value={form.getFieldValue('amount')}
                            className="text-right"
                            onChange={value => form.setFieldsValue({ amount: value })}
                            placeholder={t('package:placeholder.amount_multi_create')}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('auto_print_stamp', {
                        initialValue: true,
                    })(
                        <>
                            <Checkbox
                                checked={form.getFieldValue('auto_print_stamp')}
                                onChange={e => form.setFieldsValue({ auto_print_stamp: e.target.checked })}
                            />{' '}
                            {t('package:placeholder.print_all_multi_same_package')}
                        </>
                    )}
                </Form.Item>
            </Modal>
        </>
    )
}

export default withTranslation()(Form.create()(CreateMultiSamePackage))
